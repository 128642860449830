import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import styles from "./Terms.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaPhone, FaArrowLeft } from "react-icons/fa";
import { faqs, cookies, privacy, termsCon } from "./TermsData";

export const Terms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  const renderComp = (slug) => {
    switch (slug) {
      case "faqs":
        return faqs;
      case "cookies":
        return cookies;
      case "privacy-policy":
        return privacy;
      case "terms-and-conditions":
        return termsCon;
      default:
        <Spinner />;
    }
  };

  const renderHead = (slug) => {
    switch (slug) {
      case "faqs":
        return "FAQs";
      case "cookies":
        return "cookies";
      case "privacy-policy":
        return "privacy policy";
      case "terms-and-conditions":
        return "terms & conditions";
      default:
        <Spinner />;
    }
  };

  return (
    <Container className={styles.termsWrap}>
      <Row className={styles.innerWrap}>
        <div className={styles.left}>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2>{slug}</h2>
            </div>
            {renderComp(slug)()?.map((item) => (
              <div key={item.id} className={styles.itemWrap}>
                <h6 className={styles.title}>{item.title}</h6>
                <p className={styles.body}>{item.body}</p>
              </div>
            ))}
          </div>
        </div>
      </Row>
    </Container>
  );
};
