import React, { useState, useRef, useEffect } from "react";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaTimesCircle,
} from "react-icons/fa";
import ProgressiveImg from "./ProgressiveImg";
import CarouselDots from "./CarouselDots";
import styles from "./Carousel.module.scss";

const Carousel = ({ photos, openImg, setOpenImg }) => {
  const [current, setCurrent] = useState(openImg);
  const imageRefs = useRef([]);

  // console.log(current);
  // console.log(photos);

  useEffect(() => {
    // Preload the next and previous images in the carousel
    const preloadImages = (startIndex, endIndex) => {
      for (let i = startIndex; i <= endIndex; i++) {
        const image = new Image();
        image.src = photos[i].url;
      }
    };

    // Load the previous and next images for the current slide
    preloadImages(
      current === 0 ? photos.length - 1 : current - 1,
      current === photos.length - 1 ? 0 : current + 1,
    );
  }, [current, photos]);

  function nextSlide() {
    setCurrent((prevCurrent) =>
      prevCurrent === photos.length - 1 ? 0 : prevCurrent + 1,
    );
  }

  function prevSlide() {
    setCurrent((prevCurrent) =>
      prevCurrent === 0 ? photos.length - 1 : prevCurrent - 1,
    );
  }

  const handleImageRef = (index, element) => {
    imageRefs.current[index] = element;
  };

  return (
    <div className={styles.slider}>
      <FaTimesCircle
        className={styles.close}
        onClick={() => setOpenImg(null)}
      />
      <FaArrowCircleLeft
        className={`${styles.arrow} ${styles.arrowLeft}`}
        onClick={prevSlide}
      />
      <FaArrowCircleRight
        className={`${styles.arrow} ${styles.arrowRight}`}
        onClick={nextSlide}
      />
      <CarouselDots photos={photos} current={current} setCurrent={setCurrent} />
      {photos?.map(
        (photo, i) =>
          current === i && (
            <div key={i} className={styles.slide}>
              <ProgressiveImg
                ref={(element) => handleImageRef(i, element)}
                src={photo.src}
                placeholderSrc={require("../../../images/transparent.png")}
                alt={photo.alt}
                loading="lazy"
              />
              {/* <LazyLoadImage
                ref={(element) => handleImageRef(i, element)}
                src={photo.url}
                placeholderSrc={require("../../../images/transparent.png")}
                alt={photo.alt}
                className="img-lazy"
              /> */}
            </div>
          ),
      )}
    </div>
  );
};

export default Carousel;
