import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useInput } from "../../utils/useInput";
import GeneralForm from "./GeneralForm";
import TattooForm from "./TattooForm";
import ContactFormConfirmation from "./ContactFormConfirmation";
import styles from "./Contact.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export const ContactForm = () => {
  const location = useLocation();
  const { slug } = useParams();
  const [serviceType, serviceTypeInput, setServiceTypeInput] = useInput({
    type: "select",
    placeholder: "Choose one of the following",
    htmlFor: "serviceType",
    label: "What services are you looking for?",
    options: ["Choose one of the following", "Tattoo form", "General form"],
  });

  useEffect(() => {
    if (serviceType === "") {
      setServiceTypeInput("Choose one of the following");
    }
  }, [serviceType, setServiceTypeInput]);

  const renderForm = (form) => {
    switch (form) {
      case "General form":
        return <GeneralForm />;
      case "Tattoo form":
        return <TattooForm />;
      default:
        <></>;
    }
  };

  return (
    <>
      {!slug ? (
        <Container className={styles.contactWrap}>
          <Row className={styles.innerWrap}>
            <div className={styles.left}>
              {serviceType === "Choose one of the following" && (
                <div className={styles.content}>
                  <h1>
                    Let's get <br /> in touch
                  </h1>
                  <p>
                    We're thrilled that you are reaching out to us, and we are
                    here to assist you. <br /> Please fill out the form below,
                    and we'll respond promptly. <br /> <br /> In the meantime,
                    feel free to have a look at our{" "}
                    <Link
                      to="/terms/faqs"
                      state={{ previousLocation: location }}
                    >
                      FAQs
                    </Link>
                    .
                    <br />
                    Thank you for contacting us!
                  </p>
                </div>
              )}

              <div className={styles.formRow}>
                {serviceType === "Choose one of the following" ? (
                  <div className={styles.chooseForm}>{serviceTypeInput}</div>
                ) : null}
                {renderForm(serviceType)}
              </div>
            </div>
          </Row>
        </Container>
      ) : (
        <ContactFormConfirmation />
      )}
    </>
  );
};
