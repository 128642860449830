import React from "react";
import { Container, Row } from "react-bootstrap";
import styles from "./Contact.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useDB } from "../../../contexts/DBContext";

const ContactFormConfirmation = () => {
  const location = useLocation();
  const { pdf } = useDB();
  return (
    <Container className={styles.contactWrap}>
      <Row className={styles.innerWrap}>
        <div className={styles.left}>
          <div className={styles.content}>
            <h1>
              Thank You for <br /> Contacting Us!
            </h1>
            <p>
              Your message has been successfully sent! <br />
              <br />
              We appreciate your interest and will get back to you as soon as
              possible. <br /> In the meantime, feel free to have a look at our{" "}
              <Link to="/terms/faqs" state={{ previousLocation: location }}>
                FAQs
              </Link>
              .
            </p>
            {/* <div className={styles.links}>
              {pdf &&
                pdf.map((item) => (
                  <Link key={item.id} target="_blank" to={item.url}>
                    {item.title}
                  </Link>
                ))}
            </div> */}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default ContactFormConfirmation;
