import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
ReactGA.initialize(TRACKING_ID, {
  gtagOptions: {
    consent_mode: "denied",
  },
});

export function Gtag() {
  const location = useLocation();
  const [isConsentGranted, setIsConsentGranted] = useState(() => {
    // Load consent state from localStorage
    const storedConsent = localStorage.getItem("consentGranted");
    return storedConsent === "true"; // If stored value is 'true', set as granted
  });

  useEffect(() => {
    // Update Google Analytics consent based on stored value
    if (isConsentGranted) {
      ReactGA.gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    } else {
      ReactGA.gtag("consent", "update", {
        ad_storage: "denied",
        analytics_storage: "denied",
      });
    }

    // Store consent state in localStorage
    localStorage.setItem("consentGranted", isConsentGranted.toString());
  }, [isConsentGranted]);

  useEffect(() => {
    // Only send pageview events if consent is granted
    if (isConsentGranted) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: document.title,
      });
    }
  }, [location.pathname, isConsentGranted]);

  return null;
}

// Link event handler, ensure consent is granted before sending events
export const linkEventHandler = (link, isConsentGranted) => {
  if (isConsentGranted) {
    ReactGA.event({
      category: "Social Links",
      action: "Click",
      label: link,
    });
  }
};

// Example consent handlers
export const handleAcceptCookies = (setIsConsentGranted) => {
  setIsConsentGranted(true);
};

export const handleRejectCookies = (setIsConsentGranted) => {
  setIsConsentGranted(false);
};
