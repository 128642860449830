import React, { useState } from "react";
import styles from "./Input.module.scss";

export const useInput = ({
  type,
  placeholder,
  htmlFor,
  label,
  index,
  options,
}) => {
  const types = (type) => {
    switch (type) {
      case "text":
        return "";
      case "email":
        return "";
      case "number":
        return 0;
      case "checkbox":
        return false;
      case "select":
        return placeholder;
      default:
        return "";
    }
  };

  function convertNumber(n) {
    return n <= 9 ? `0${n}` : n;
  }

  const [value, setValue] = useState(types(type));
  const input = (
    <div key={index} className={styles.inputWrap}>
      {/* {index && (
        <div className={styles.number}>
          <p>{convertNumber(index)}</p>
        </div>
      )} */}
      <div className={styles.input}>
        <label className={styles.filterLabel} htmlFor={htmlFor}>
          {label}
        </label>
        {type !== "select" ? (
          <input
            value={value}
            id={htmlFor}
            onChange={(e) =>
              setValue(type !== "checkbox" ? e.target.value : e.target.checked)
            }
            checked={type === "checkbox" ? value : false}
            type={type}
            placeholder={placeholder}
          />
        ) : (
          <select id={htmlFor} onChange={(e) => setValue(e.target.value)}>
            {options &&
              options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </select>
        )}
      </div>
    </div>
  );
  return [value, input, setValue];
};
