import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../pages/artist/ArtistLayout.module.scss";
import { Link } from "react-router-dom";
import ProgressiveImg from "../pages/artist/ProgressiveImg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from "../pages/artist/Carousel.js";

const InstagramComp = ({ drive, instagram, openImg, setOpenImg }) => {
  const sliderRef = useRef(null);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (drive.length > 0) {
      const images = drive.map((src) => ({
        src,
        alt: "Mr Greg Tattoo artist portfolio art work",
      }));
      setPosts(images);
    }
    return () => {
      setPosts([]);
    };
  }, [drive.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sliderRef?.current && posts.length > 0) {
        // @ts-ignore
        sliderRef.current.slickGoTo(0);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [sliderRef, posts]);

  var settings = {
    dots: true,
    arrows: false,
    infinite: posts && posts?.length >= 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: posts && posts?.length >= 3 ? true : false,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: posts && posts?.length >= 2 ? true : false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: posts && posts?.length >= 2 ? true : false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 688,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };

  // console.log(openImg);

  return openImg === null ? (
    <div style={{ display: "block" }}>
      {instagram.length ? (
        <Slider ref={sliderRef} {...settings}>
          {posts.map((item) => (
            <div key={item.id} className={styles.inner}>
              <div className={styles.imgWrap}>
                <Link to={item.permalink} target="_blank">
                  {/* <ProgressiveImg
                      src={
                        item.media_type !== "VIDEO"
                          ? item.media_url
                          : item.thumbnail_url
                      }
                      placeholderSrc={require("../../images/transparent.png")}
                      alt={`https://www.instagram.com/absolute_bodyart/`}
                    /> */}
                  {/* <LazyLoadImage
                      src={
                        item.media_type !== "VIDEO"
                          ? item.media_url
                          : item.thumbnail_url
                      }
                      placeholderSrc={require("../../images/transparent.png")}
                      alt={`https://www.instagram.com/absolute_bodyart/`}
                      className="img-lazy"
                    /> */}
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <Slider ref={sliderRef} {...settings}>
          {posts.map((item, i) => (
            <div key={i} className={styles.inner}>
              <div className={styles.imgWrap}>
                {/* <img
                    src={item}
                    // placeholderSrc={require("../../images/transparent.png")}
                    alt="https://www.instagram.com/mrgregtattoo/"
                  /> */}
                <LazyLoadImage
                  src={item.src}
                  placeholderSrc={require("../../images/transparent.png")}
                  alt={`https://www.instagram.com/absolute_bodyart/`}
                  className="img-lazy"
                  onClick={() => setOpenImg(i)}
                />
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  ) : (
    <div className={styles.carouselWrap}>
      <Carousel photos={posts} openImg={openImg} setOpenImg={setOpenImg} />
    </div>
  );
};

export default InstagramComp;
