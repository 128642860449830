import React, { useEffect, useRef } from "react";
import { ContactForm } from "../pages/contact/ContactForm";
import styles from "./Modal.module.scss";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ArtistLayout } from "../pages/artist/ArtistLayout";
import { Terms } from "../pages/terms/Terms";
import {
  FaFacebookF,
  FaInstagram,
  FaPhone,
  FaArrowLeft,
  FaTimes,
} from "react-icons/fa";
import { Container } from "react-bootstrap";

const Modal = () => {
  const modalRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   const observerRefValue = modalRef.current;
  //   if (observerRefValue) {
  //     document.body.style.overflow = "hidden";
  //   }
  //   return () => {
  //     document.body.style.overflow = "initial";
  //     abortController.abort();
  //   };
  // }, []);

  const renderModal = (id) => {
    switch (id) {
      case "artist":
        return <ArtistLayout />;
      case "contact":
        return <ContactForm />;
      case `terms`:
        return <Terms />;
      case id:
        return <div style={{}}>{id}</div>;
      default:
        <></>;
    }
  };
  return (
    <div
      ref={modalRef}
      // onClick={() => navigate("/", { state: { from: location } })}
      className={styles.modalWrapper}
    >
      <div className={styles.overlay}></div>
      <div onClick={(e) => e.stopPropagation()} className={`${styles.inner}`}>
        <Container>
          <div
            onClick={() => navigate("/", { state: { from: location } })}
            className={styles.goBack}
          >
            <FaArrowLeft />
            <p>Go back</p>
          </div>
        </Container>
        {renderModal(id)}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Modal;
