import React, { useContext, useState, useEffect } from "react";
import { db, storage } from "../firebase";
import { getDocs, collection } from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";

const DBContext = React.createContext();

export function useDB() {
  return useContext(DBContext);
}

export function DBProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [artists, setArtists] = useState([]);
  const [instagramPhotos, setInstagramPhotos] = useState([]);
  const [artistCollection, setArtistCollection] = useState([]);
  const [pdf, setPdf] = useState([]);

  const artistCollectionRef = collection(db, "artists");
  const instagramFilesRef = ref(storage, "instagram/");
  const pdfFilesRef = collection(db, "downloads");

  // Fetch Artists
  useEffect(() => {
    const getArtistList = async () => {
      try {
        setLoading(true);
        const data = await getDocs(artistCollectionRef);
        const artistsData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setArtists(artistsData.sort((a, b) => a?.order - b?.order));
      } catch (error) {
        console.error("Error fetching artists:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!artists.length) getArtistList();
  }, [artistCollectionRef, artists]);

  // Fetch Instagram Photos
  useEffect(() => {
    const getInstagramPhotos = async () => {
      try {
        setLoading(true);
        const res = await listAll(instagramFilesRef);
        const urls = await Promise.all(
          res.items.map((item) => getDownloadURL(item)),
        );
        setInstagramPhotos(urls);
      } catch (error) {
        console.error("Error fetching Instagram photos:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!instagramPhotos.length) getInstagramPhotos();
  }, [instagramPhotos]);

  // Fetch Artist Collections
  useEffect(() => {
    const fetchArtistCollections = async () => {
      const collections = [];
      try {
        setLoading(true);
        await Promise.all(
          artists.map(async (artist) => {
            const artistRef = ref(storage, `artistsPortfolio/${artist.ig}`);
            const res = await listAll(artistRef);
            const urls = await Promise.all(
              res.items.map((item) => getDownloadURL(item)),
            );
            urls.forEach((url) => collections.push({ url, artist: artist.ig }));
          }),
        );
        setArtistCollection(collections);
      } catch (error) {
        console.error("Error fetching artist collections:", error);
      } finally {
        setLoading(false);
      }
    };

    if (artists.length && !artistCollection.length) fetchArtistCollections();
    // }, [artists, artistCollection]);
  }, []);

  // Fetch PDFs
  useEffect(() => {
    const getPdfs = async () => {
      try {
        setLoading(true);
        const data = await getDocs(pdfFilesRef);
        const pdfData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setPdf(pdfData);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!pdf.length) getPdfs();
  }, [pdfFilesRef, pdf]);

  // Context Value
  const value = {
    pdf,
    artists,
    instagramPhotos,
    artistCollection,
    loading,
  };

  return <DBContext.Provider value={value}>{children}</DBContext.Provider>;
}
