import React, { useEffect } from "react";
import Signup from "./admin/Signup";
import Login from "../components/admin/Login";
import NavBar from "../components/pages/nav/NavBar";
import Footer from "../components/pages/footer/Footer";
import ForgotPassword from "../components/admin/ForgotPassword";
import Home from "../components/pages/home/Home";
import NotFound from "../components/pages/notFound/NotFound";
import ScrollToTop from "./utils/ScrollToTop";
import { useLocation, Routes, Route, Outlet } from "react-router-dom";
import "./App.scss";
import Modal from "./utils/Modal";
import { Gtag, linkEventHandler } from "./utils/Gtag";
import { AuthProvider } from "../contexts/AuthContext";
import { DBProvider } from "../contexts/DBContext";

function App() {
  const location = useLocation();
  const home = <Home key={"home"} />;
  const modal = <Modal key={"modal"} />;

  useEffect(() => {
    linkEventHandler(location.pathname + location.search);
  }, [location.pathname, location.search]);

  return (
    <AuthProvider>
      <DBProvider>
        <Gtag />
        <ScrollToTop state={location.state} />
        <NavBar />
        <Routes>
          <Route path="/" element={home} index />
          <Route path="/:id" element={[modal]} />
          <Route path="/:id/:slug" element={[modal]} />
          <Route path="admin/login" element={<Login />} />
          <Route path="admin/register" element={<Signup />} />
          <Route path="admin/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <Outlet />
      </DBProvider>
    </AuthProvider>
  );
}

export default App;
