import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import styles from "./Home.module.scss";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Home = () => {
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const [smBreakpoint] = useState(767);

  function scrollToSection(id) {
    const el = document.getElementById(id);
    el?.scrollIntoView({
      behavior: "smooth",
      block: width > smBreakpoint ? "center" : "start",
    });
  }

  useEffect(() => {
    const abortController = new AbortController();
    const handleWindowResize = () => setWidth(window.innerWidth);
    if (!abortController.signal.aborted) {
      window.addEventListener("resize", handleWindowResize);
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Container fluid className={styles.homeWrapper}>
        <div id="home" className={styles.heroBG}>
          <Container className={styles.sectionWrap}>
            <Row className={styles.heroRow}>
              <Col className={styles.heroWrap}>
                <div className={styles.contentWrap}>
                  <h1>
                    Mr Greg <br /> <span>Tattoo</span>
                  </h1>
                  <p>
                    Welcome to Mr Greg Tattoo, a renowned tattoo and piercing
                    studio located in the heart of Edinburgh's vibrant Leith
                    district.
                  </p>
                  <p>Located at 53 Easter Road, Edinburgh EH7 5PL, UK</p>
                </div>
                <div className={styles.buttonWrap}>
                  <Button
                    className={styles.btnPrimary}
                    onClick={() => scrollToSection("contact")}
                  >
                    Contact us
                  </Button>
                </div>
                <div className={styles.socialWrap}>
                  <div className={styles.innerWrap}>
                    <Link
                      to="https://www.facebook.com/MrGregTattoo/"
                      target="_blank"
                    >
                      {width < smBreakpoint ? <FaFacebookF /> : "Facebook"}
                    </Link>
                    <Link
                      to="https://www.instagram.com/mrgregtattoo/"
                      target="_blank"
                    >
                      {width < smBreakpoint ? <FaInstagram /> : "Instagram"}
                    </Link>
                    <Link to="tel:0044131 629 1183">
                      {width < smBreakpoint ? <FaPhone /> : "Call us"}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid className={styles.overlayBG}>
          <Container className={`${styles.sectionWrap}`}>
            <div className={styles.spacer} />

            <Row id="artists" className={styles.aboutWrap}>
              <Col className={styles.wrap}>
                <div className={styles.left}>
                  <h2>Artists</h2>
                </div>
                <div className={styles.right}>
                  <div className={styles.content}>
                    <p>
                      Our artist are specializing in various styles including
                      realistic, black and gray, line work, graphic designs,
                      biomechanical, and vibrant color tattoos.{" "}
                    </p>
                    <p>
                      Mr. Greg Tattoo Studio is a well-established tattoo studio
                      located in the heart of Edinburgh. Famous for its
                      high-quality standards, the studio bonds a team of skilled
                      tattoo artists dedicated to delivering great designs and
                      customer experiences. Whether you're seeking a custom
                      piece or exploring a variety of styles, Mr. Greg Tattoo
                      Studio ensures that each tattoo is a unique work of art.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <div className={styles.spacer} />

            <Row className={styles.artistWrap}>
              <Col className={styles.wrap}>
                <div className={styles.cardsWrap}>
                  <div className={styles.card}>
                    <div className={styles.img}>
                      <LazyLoadImage
                        src={require("../../../images/resized23.jpg")}
                        placeholderSrc={require("../../../images/transparent.png")}
                        alt="Lena tattoos portrait image"
                        className="img-lazy"
                      />
                    </div>
                    <div className={styles.content}>
                      <h4>Lena</h4>
                      <p>
                        Lena is a talented tattoo artist known for her realistic
                        color tattoos and her ability to blend multiple styles.
                        Her style combines realism with elements of abstract and
                        watercolor designs, creating tattoos that are both
                        vibrant and unique. Lena's attention to detail and use
                        of color result in lifelike and visually stunning
                        pieces, making her the most sought-after artist for
                        those seeking distinctive and personalized body art.
                      </p>
                      <Link to={`/artist/lena`}>View portfolio</Link>
                    </div>
                  </div>

                  <div className={styles.card}>
                    <div className={styles.img}>
                      <LazyLoadImage
                        src={require("../../../images/resized3.jpg")}
                        placeholderSrc={require("../../../images/transparent.png")}
                        alt="Jarek tattoos portrait image"
                        className="img-lazy"
                      />
                    </div>
                    <div className={styles.content}>
                      <h4>Mr Rzymski</h4>
                      <p>
                        Mr Rzymski, a legendary tattoo artist with 30 years of
                        experience, specializes exclusively in black and gray
                        realistic tattoos. His unparalleled craftsmanship and
                        ability to create lifelike, intricate designs have
                        cemented his status as a true master in the tattoo
                        industry. With a keen eye for detail and a dedication to
                        perfection, Mr Rzymski transforms every tattoo into a
                        breathtaking work of art.
                      </p>
                      <Link to={`/artist/MrRzymski`}>View portfolio</Link>
                    </div>
                  </div>

                  <div className={styles.card}>
                    <div className={styles.img}>
                      <LazyLoadImage
                        src={require("../../../images/resized13.jpg")}
                        placeholderSrc={require("../../../images/transparent.png")}
                        alt="Magda tattoos portrait image"
                        className="img-lazy"
                      />
                    </div>
                    <div className={styles.content}>
                      <h4>Magda</h4>
                      <p>
                        Magda is a renowned tattoo artist known for her dotwork
                        and black graphical designs. Her work often blends
                        detailed dot patterns with bold, modern aesthetics,
                        frequently incorporating elements of nature, abstract
                        forms, and sacred geometry. This unique combination
                        makes her tattoos highly wanted for their precision and
                        visual impact.
                      </p>
                      <Link to={`/artist/magda`}>View portfolio</Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <div className={styles.spacer} />

            <Row className={styles.piercingWrap}>
              <Col className={styles.wrap}>
                <div className={styles.left}>
                  <h2>
                    Body Piercing
                    <br /> By Rafal
                  </h2>
                  <p>
                    Our professional piercer is an experienced artist with a
                    keen eye for detail. He provides personalised consultations
                    to ensure the best placement and jewelry selection. Whether
                    you’re a novice or a veteran, our piercer makes your
                    experience smooth and enjoyable, bringing your body art
                    ideas to life with expert care and creativity.
                  </p>
                  <Link to={`/artist/rafal`}>View portfolio</Link>
                </div>
                <div className={styles.right}>
                  <h2>
                    Body Piercing
                    <br /> By Rafal
                  </h2>
                  <div className={styles.imgWrap}>
                    <LazyLoadImage
                      src={require("../../../images/resized4.jpg")}
                      placeholderSrc={require("../../../images/transparent.png")}
                      alt="Rafal piercing and tattoos portrait image"
                      className="img-lazy"
                    />
                  </div>
                </div>
              </Col>
            </Row> */}

            <div className={styles.spacer} />

            <Row className={styles.studioBannerWrap}>
              <Col className={styles.wrap}>
                <div className={styles.imgWrap}>
                  <LazyLoadImage
                    src={require("../../../images/banner.png")}
                    placeholderSrc={require("../../../images/transparent.png")}
                    alt="Mr Greg Tattoo banner photograph of the studio's workshop"
                    className="img-lazy"
                  />
                </div>
                <div className={styles.contentWrap}>
                  <p>
                    Mr Greg Tattoo ensures high-quality artistry tailored to
                    each client’s vision. With a dedicated team of skilled
                    artists, the studio maintains a reputation for
                    professionalism and creativity. Becoming the ultimate stop
                    for tattoo enthusiasts craving extraordinary body art
                    adventures.
                  </p>
                </div>
              </Col>
            </Row>

            <div className={styles.spacer} />

            <Row id="contact" className={styles.contactWrap}>
              <Col className={styles.wrap}>
                <div className={styles.left}>
                  <h2>Contact us</h2>
                  <p>
                    Please don’t hesitate to reach out. We are open to any and
                    all inquiries.
                  </p>
                  {/* <a href="mailto:info@mrgregtattoo.com">
                    info@mrgregtattoo.com
                  </a> */}
                  <Button
                    as={Link}
                    to="/contact"
                    state={{ previousLocation: location }}
                    className={styles.btnPrimary}
                  >
                    Contact us
                  </Button>
                  <div className={styles.social}>
                    <Link
                      to="https://www.facebook.com/MrGregTattoo/"
                      target="_blank"
                    >
                      {width < smBreakpoint ? <FaFacebookF /> : "Facebook"}
                    </Link>
                    <Link
                      to="https://www.instagram.com/mrgregtattoo/"
                      target="_blank"
                    >
                      {width < smBreakpoint ? <FaInstagram /> : "Instagram"}
                    </Link>
                    <Link to="tel:0044131 629 1183">
                      {width < smBreakpoint ? <FaPhone /> : "Call us"}
                    </Link>
                  </div>
                </div>
                <div className={styles.right}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2233.4227775358722!2d-3.1743411879248233!3d55.9593758759497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887b8749e95952d%3A0x508535424a9a50b0!2sMr%20Greg%20Tattoo!5e0!3m2!1sen!2suk!4v1719762028239!5m2!1sen!2suk"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "100%",
                      minHeight: "400px",
                    }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
            </Row>

            <div className={styles.spacer} />
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default Home;
