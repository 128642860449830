import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./NavBar.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeBackground, setActiveBackground] = useState(false);
  const [hideNavbar, setHideNavbar] = useState(false);

  useEffect(() => {
    let currentScroll = 0;
    let screenHeight = window.innerHeight;
    const onScroll = () => {
      currentScroll = window.pageYOffset;
      if (currentScroll > screenHeight) {
        setActiveBackground(true);
      } else {
        setActiveBackground(false);
      }

      if (currentScroll > 120) {
        setHideNavbar(true);
      } else {
        setHideNavbar(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      debugger;
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const navigateToSection = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { from: location } });
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 300);
    } else {
      scrollToSection(sectionId);
    }
  };

  return (
    <Container
      fluid
      className={`${styles.navbarWrapper} ${
        activeBackground && styles.activeBackground
      } ${hideNavbar && styles.hideNavbar}`}
    >
      <Container className={styles.sectionWrap}>
        <Row className={styles.row}>
          <Col className={styles.wrap}>
            <div
              onClick={() => {
                navigate("/");
                scrollToSection("home");
              }}
              className={styles.logoWrap}
            >
              <img
                src={require("../../../images/logo-black.png")}
                alt="Mr Greg Tattoo logo"
              />
            </div>
            <div className={styles.linksWrap}>
              <a onClick={() => navigateToSection("artists")}>Artists</a>
              <a onClick={() => navigateToSection("contact")}>Contact</a>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default NavBar;
